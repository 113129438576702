import React, { useState, useEffect } from 'react';
import './styles/App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
import { useMsalAuthentication, useMsal, useAccount } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import { OwnerTable } from "./components/tables/Pages/OwnerTable";
import { CollabTable } from "./components/tables/Pages/CollabTable";
import { CSVLink } from "react-csv";
import {PopUp} from "./components/PopUp/Modal"
import logo from "./components/assets/logo.png";
import { UserAgentApplication } from 'msal';
import {callAPI} from "./api"
import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Box,
    Heading,
    Link,
    VStack,
    Flex,
    Spacer,
    Image,
    Button,
    Text,
    UnorderedList,
    ListItem,
    Spinner
  } from "@chakra-ui/react";
/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
// Dummy Commit
// Dummy Commit Test
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    //const [AccessToken, setAccessToken] = useState(null);

    // Silently acquires an access token which is then attached to a request for MS Graph data
    // const fetchCreds = async () => {
    // const response = await instance, dummy commit
    // .acquireTokenSilent({
    //     ...loginRequest,
    //     account: accounts[0],
    // })
    // setAccessToken(response.accessToken)
    //   return response
    
    // }
    

    // useEffect(() => {
    //   fetchCreds()
    //   .then((response) => {
    //     callMsGraph(response.accessToken)
    //     .then((response) => setGraphData(response));
    // });
    // }, [])

    const fetchCreds = async () => 
    { 
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
    }).then((response) => {
      callMsGraph(response.accessToken).then(response => setGraphData(response));
  });
    
    }
    useEffect(() => {
      fetchCreds()
    }, [])


    
    if (!graphData) {
      console.log("Loading SSO...")
      return null

    }

    let props = {
      //token: AccessToken,
      email: graphData.mail
    }
    console.log(graphData.mail)

    return (
      <UserData {...props}/>
    );
    
};

const UserData = (props) => {
  //] = useState(null);
  const { accounts } = useMsal();
  const [CollaboratorApiData, CollaboratorSetApiData] = useState(null);
  const [OwnerApiData, OwnerSetApiData] = useState(null);
  const account = useAccount(accounts[0] || {});

  const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_nodeAPIclientID}/Files.Read`,
        authority: "https://login.microsoftonline.com/15ccb6d1-d335-4996-b6f9-7b6925f08121",
        redirectUri: process.env.REACT_APP_RedirectURL,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const msalInstance = new UserAgentApplication(msalConfig);
// console.log(account)


    const GetApiToken = async () => {
   const response = await msalInstance.acquireTokenSilent({
      scopes: [`api://${process.env.REACT_APP_nodeAPIclientID}/Files.Read`],
      account: account})
 return response
    };

    useEffect(()=> {
      if (accounts.length > 0) {
        GetApiToken()
      .then((response) => {
        if(response) { // props.email.toLowerCase()
          callAPI(response.accessToken,'Collaborator', props.email.toLowerCase())
        .then((result) => CollaboratorSetApiData(result))
      }
      })
      }
    }, [])

    useEffect(()=> {
      if (accounts.length > 0) {
        GetApiToken()
      .then((response) => {
        if(response) {
          callAPI(response.accessToken,'Owner', props.email.toLowerCase())
        .then((result) => OwnerSetApiData(result))
      }
      })
      }
    }, [])


    // if (!OwnerApiData) {
    //   return 
    //   <VStack alignItems={'center'}><Heading size={'lg'} color={'white'}>Loading...</Heading><Spinner
    //   thickness='4px'
    //   speed='0.8s'
    //   emptyColor="#0C2340"
    //   color='white'
    //   size='xl'
    // /></VStack>;
    // }
  
    // if (!CollaboratorApiData) {
    //   return <VStack alignItems={'center'}><Heading size={'lg'} color={'white'}>Loading...</Heading><Spinner
    //   thickness='4px'
    //   speed='0.8s'
    //   emptyColor= "#0C2340"
    //   color='white'
    //   size='xl'
    // /></VStack>
    // }

  return (
<>
    {(CollaboratorApiData && OwnerApiData) ? 
    <VStack pt={4}  alignItems={'center'}>
    <Heading textColor={'white'} fontFamily="Arial" size="md">
    Box Files/Folders I Own Containing Collaborators Outside My Business (would need to reestablish these collaborators)
          </Heading>
          <OwnerTable data = {OwnerApiData} />
          {OwnerApiData.length? 
          <Button textColor={'white'} bgColor={"brand.Blue"} alignSelf={"end"}>
            <CSVLink filename='Box_Owner_Report' data={OwnerApiData}>Export CSV</CSVLink>
          </Button> : <></>
}
          <Heading textColor={'white'} fontFamily="Arial" size="md">
          Box Files/Folders Owned By a User Outside of My Business That I Currently Have Access To (you would need to be reinvited)
          </Heading>
          <CollabTable data = {CollaboratorApiData} />
          {CollaboratorApiData.length? 
          <Button textColor={'white'}  bgColor={"brand.Blue"} alignSelf={"end"}>
            <CSVLink CSVlink filename='Box_Collab_Report' data={CollaboratorApiData}>Export CSV</CSVLink>
          </Button> : <></>
}
          </VStack> 
          
          : 
          
          <VStack alignItems={'center'}>
          <Heading size={'lg'} color={'white'}>Loading...</Heading><Spinner
          thickness='4px'
          speed='0.8s'
          emptyColor= "#0C2340"
          color='white'
          size='xl'
        />
        </VStack>
}
        </>
  );

}
/**
 * If a user is authenticated the ProfileContent {Dummy Commit} component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */

export  default function App() {
    useMsalAuthentication(InteractionType.Redirect);
    return (
      <>
        <AuthenticatedTemplate>
            <Box>
      <Flex align={"center"}>
        <Spacer />
        <Image w="100px" src={logo} />
      </Flex>
      <Flex justify={"center"} minH="vh100">
        <VStack>
          <Heading
            pt={"2"}
            pos="absolute"
            top="0"
            alignSelf={"center"}
            pb={"1"}
            fontFamily="Arial"
            size="lg"
            color={'white'}
          >
            GE to GE Vernova Box Collaboration Review Portal
          </Heading>
          <VStack alignItems={'start'} bgColor={'white'} rounded={10} p={3}>
            <Box maxW='65rem'>
          <Heading size={'md'} fontFamily={'arial'} color={"colors.DarkIndigo"} mb={1}>What is happening?</Heading>

          <Text fontSize='sm' fontFamily={'arial'} color={"colors.DarkIndigo"}>As part of the <Text as={'b'}   >GE Vernova separation, GE Vernova Box data is being migrated to a dedicated Box environment.</Text>
            {" "}To be compliant in our data protection policies, we must regulate who has access to the GE and GE Vernova environments. 
            This will result in some disruption to the access users have to data across businesses. </Text>
            <Heading size={'md'} fontFamily={'arial'} color={"colors.DarkIndigo"} mb={1} mt={2}>When is this happening?</Heading> 
            <UnorderedList>
  <ListItem fontSize='sm' color={"colors.DarkIndigo"}>Vernova environment migration/separation: <Text  as={'b'}    >May 19th 12PM EST - May 21st 11PM EST</Text></ListItem>
</UnorderedList>
<Heading size={'md'} fontFamily={'arial'} color={"colors.DarkIndigo"} mb={1} mt={2}>What Actions Can I Take?</Heading>
          <Text fontSize='sm' fontFamily={'arial'} color={"colors.DarkIndigo"}>The two tables below outline your collaborations within the Box tool.<br/><br/></Text>
          <Text fontSize='md' as={'b'}>If you are a GE Aerospace or GE Corporate Employee/Contractor:</Text>
          <Text color={"colors.DarkIndigo"} fontSize='sm'>The first table, <Text  as={'b'}    > Box Files/Folders I Own Containing Collaborators Outside My Business</Text>, displays Box folders that you own that have collaborators within the defined GE Vernova business.
          As of May 19th, these users will no longer have access to the files and folders outlined in this table. 
            <Text  as={'b'}    > If the user requires continued access, you as the owner will need to reinstate their access within Box on May 21st 2023.</Text> <Text as={'u'}>Please refer to the instructions included<Link a='b' color={'blue'} href="https://geit.service-now.com/kb?id=kb_article_view&sysparm_article=GEKB2003326" isExternal> here<ExternalLinkIcon mx='1px' color={'colors.DarkIndigo'} /></Link></Text></Text>


            <Text fontSize='sm' color={"colors.DarkIndigo"}><br/>The second table, <Text  as={'b'}    > Box Files/Folders Owned By a User Outside of My Business That I Currently Have Access To</Text>, displays folders/files owned by a Vernova user that you will lose access to on May 19th. 
            <Text  as={'b'}  > If you require continued access to these files/ folders you will need to request this access be reinstated by the named file/folder owner.</Text></Text>

            <Text fontSize='md' as={'b'}><br/>If you are a GE Vernova Employee/Contractor:</Text>
          <Text color={"colors.DarkIndigo"} fontSize='sm'>The first table, <Text  as={'b'}    > Box Files/Folders I Own Containing Collaborators Outside My Business</Text>, displays Box folders that you own that have collaborators outside of the GE Vernova business.
          As of May 19th, these users will no longer have access to the files and folders outlined in this table.
            <Text  as={'b'}    > If the user requires continued access, you as the owner will need to reinstate their access within Box on May 21st 2023.</Text> <Text as={'u'}>Please refer to the instructions included<Link a='b' color={'blue'} href="https://geit.service-now.com/kb?id=kb_article_view&sysparm_article=GEKB2003326" isExternal> here<ExternalLinkIcon mx='1px' color={'colors.DarkIndigo'} /></Link></Text></Text>


            <Text fontSize='sm' color={"colors.DarkIndigo"}><br/>The second table, <Text  as={'b'}    > Box Files/Folders Owned By a User Outside of My Business That I Currently Have Access To</Text>, displays folders/files owned by a GE Aerospace or GE Corporate user that you will lose access to on May 19th. 
            <Text  as={'b'}  > If you require continued access to these files/ folders you will need to request this access be reinstated by the named file/folder owner.</Text></Text>

            </Box>
          </VStack>
          <Box>
          <ProfileContent />
          </Box>
          </VStack>
          
      </Flex>
      <PopUp/>
    </Box>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <h5 className="card-title">Please sign-in to see your profile information.</h5>
        </UnauthenticatedTemplate>
    </>
  );
}
